// eslint-disable-next-line no-unused-vars
import { defineComponent, reactive, toRefs, inject, watch, onMounted } from "vue"; // eslint-disable-next-line no-unused-vars

import { useStore } from "vuex"; // import { useRouter } from "vue-router";

export default defineComponent({
  name: "InfoItem",
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    }
  },
  setup: function setup(props, ctx) {
    // eslint-disable-next-line no-unused-vars
    // const router = useRouter();
    // const state = reactive({
    //   user: {},
    // });
    // const planList = inject("planList");
    // console.log(planList);
    function back() {
      ctx.emit("back");
    } // const store = useStore();
    // state.user = store.state.user;
    // watch(
    //   () => state.active,
    //   val => {
    //     router.push(val);
    //   }
    // );
    // onMounted(() => {
    //   state.active = router.currentRoute.value.path;
    // });


    return {
      // ...toRefs(state),
      count: 1,
      back: back
    };
  }
});