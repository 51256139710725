var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "info_item"
  }, [_c('div', {
    staticClass: "info_item_title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.value ? _c('div', {
    staticClass: "info_item_value",
    attrs: {
      "title": _vm.value
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")]) : _c('div', {
    staticClass: "info_item_slot"
  }, [_vm._t("default")], 2)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };